'use client';

import { Button } from '@/components/Atoms/Button';
import { DownChevron } from '@/components/Atoms/Icons';
import ExpandingBlock from '@/components/utility/ExpandingBlock';
import { withFragmentArray } from '@liquorice/gql-utils';
import { useState } from 'react';
import { ACCORDION_ITEM_FRAGMENT } from '../Blocks/blockComponents/AccordionBlock';

// multi panel accordion with individual toggle and global open/close
const AccordionMulti = withFragmentArray(ACCORDION_ITEM_FRAGMENT, (data) => {
  const [activeIndex, setActiveIndex] = useState(data.map(() => false));
  const [allOpen, setAllOpen] = useState(false);

  const toggleAll = () => {
    setAllOpen((prevAllOpen) => {
      setActiveIndex((prev) => prev.map(() => !prevAllOpen));
      return !prevAllOpen;
    });
  };

  return (
    <div className="text-midnight-blue-500">
      <div className="flex justify-end border-b border-b-grey-100 py-4">
        <Button color="lightBlue" element={'button'} size={'small'} onClick={toggleAll}>
          {allOpen ? <>Collapse All &minus;</> : <>Expand All +</>}
        </Button>
      </div>
      {data.map((panel, index) => (
        <div key={index} className="border-b border-b-grey-100">
          <button
            className="flex w-full items-center justify-between py-4"
            onClick={() => {
              setActiveIndex((prev) => prev.map((_, i) => (i === index ? !prev[i] : prev[i])));
            }}
            aria-controls={panel.id + 'content'}
            aria-expanded={activeIndex[index]}
            id={panel.id + 'header'}>
            <h5 className="text-left">{panel.title}</h5>
            <DownChevron className="shrink-0 stroke-midnight-blue-500" />
          </button>
          <ExpandingBlock expanded={activeIndex[index]}>
            <div
              className="prose-lg max-w-full pb-6"
              dangerouslySetInnerHTML={{ __html: panel.htmlContentDefault || '' }}
              id={panel.id + 'content'}
              aria-labelledby={panel.id + 'header'}
              role="region"
            />
          </ExpandingBlock>
        </div>
      ))}
    </div>
  );
});

export default AccordionMulti;
