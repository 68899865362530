export const CameraIcon = ({ className = 'fill-midnight-blue-500' }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-[1.25em] w-auto ${className}`}>
    <path d="M12 15.75C13.6569 15.75 15 14.4069 15 12.75C15 11.0931 13.6569 9.75 12 9.75C10.3431 9.75 9 11.0931 9 12.75C9 14.4069 10.3431 15.75 12 15.75Z" />
    <path d="M20.25 6.75H17.4844C17.3438 6.75 17.1694 6.65906 17.0334 6.51562L15.8175 4.59656C15.7983 4.56611 15.7768 4.53712 15.7533 4.50984C15.3333 4.02 14.7656 3.75 14.1562 3.75H9.84375C9.23438 3.75 8.66672 4.02 8.24672 4.50984C8.2232 4.53712 8.20173 4.56611 8.1825 4.59656L6.96656 6.51844C6.8625 6.63187 6.71625 6.75281 6.5625 6.75281V6.37781C6.5625 6.1789 6.48348 5.98813 6.34283 5.84748C6.20218 5.70683 6.01141 5.62781 5.8125 5.62781H4.6875C4.48859 5.62781 4.29782 5.70683 4.15717 5.84748C4.01652 5.98813 3.9375 6.1789 3.9375 6.37781V6.75281H3.75C3.15345 6.75343 2.58152 6.99069 2.1597 7.41251C1.73787 7.83433 1.50062 8.40627 1.5 9.00281V18C1.50062 18.5965 1.73787 19.1685 2.1597 19.5903C2.58152 20.0121 3.15345 20.2494 3.75 20.25H20.25C20.8465 20.2494 21.4185 20.0121 21.8403 19.5903C22.2621 19.1685 22.4994 18.5965 22.5 18V9C22.4994 8.40345 22.2621 7.83152 21.8403 7.40969C21.4185 6.98787 20.8465 6.75062 20.25 6.75ZM12 17.25C11.11 17.25 10.24 16.9861 9.49993 16.4916C8.75991 15.9971 8.18314 15.2943 7.84254 14.4721C7.50195 13.6498 7.41283 12.745 7.58647 11.8721C7.7601 10.9992 8.18868 10.1974 8.81802 9.56802C9.44736 8.93868 10.2492 8.5101 11.1221 8.33647C11.995 8.16283 12.8998 8.25195 13.7221 8.59254C14.5443 8.93314 15.2471 9.50991 15.7416 10.2499C16.2361 10.99 16.5 11.86 16.5 12.75C16.4986 13.9431 16.0241 15.0869 15.1805 15.9305C14.3369 16.7741 13.1931 17.2486 12 17.25Z" />
  </svg>
);
