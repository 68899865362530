export const FloorplanIcon = ({ className = 'fill-midnight-blue-500' }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-[1.25em] w-auto ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12.087V20.1739H12.0497H20.0994V17.7143V15.2547H19.6149H19.1304V17.1925V19.1304H15.6669H12.2034L12.1824 18.2174L12.1615 17.3043L11.6584 17.2823L11.1553 17.2603V18.1953V19.1304H8.06211H4.96894V15.5528V11.9752H8.06211H11.1553V13.0186V14.0621H11.6785H12.2018L12.1816 12.5155L12.1615 10.9689L8.56522 10.9496L4.96894 10.9303V7.98683V5.04348H12.0497H19.1304V6.34783V7.65217H17.6025H16.0745V8.17391V8.69565H17.6025H19.1304V9.78415V10.8726L20.3416 12.2252C21.0078 12.9691 21.5863 13.5817 21.6273 13.5865C21.6683 13.5913 21.8601 13.4529 22.0535 13.2791L22.4052 12.9628L21.2523 11.7057L20.0994 10.4486V7.22435V4H12.0497H4V12.087Z"
    />
  </svg>
);
