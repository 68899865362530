'use client';

import { LINK_FIELD_FRAGMENT, parseLinkFields } from '@/lib/parsers/linkField';
import { withFragmentArray } from '@liquorice/gql-utils';
import { useState } from 'react';
import { DownChevron } from '../Atoms/Icons';
import ExpandingBlock from '../utility/ExpandingBlock';

export interface QuickLinksProps {
  label?: string;
}

export const QuickLinks = withFragmentArray(
  LINK_FIELD_FRAGMENT,
  (data, { label }: QuickLinksProps) => {
    const [quickLinksOpen, setQuickLinksOpen] = useState(false);
    const toggleQuickLinks = () => setQuickLinksOpen(!quickLinksOpen);
    const links = parseLinkFields(data);
    return (
      <div className="relative h-14 text-midnight-blue-500">
        <div className="absolute left-0 top-0 z-10 max-w-xs rounded-[28px] bg-white font-medium">
          <button
            onClick={toggleQuickLinks}
            className="flex h-14 w-full items-center justify-between px-5 text-base">
            {label || <>I&apos;m looking for&hellip;</>}
            <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-midnight-blue-500">
              <DownChevron className={`stroke-white ${quickLinksOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>
          <ExpandingBlock expanded={quickLinksOpen}>
            <div className="flex flex-col gap-5 px-5 pb-6 text-base font-normal">
              {links.map((link, index) => (
                <a key={index} {...link} />
              ))}
            </div>
          </ExpandingBlock>
        </div>
      </div>
    );
  },
);
