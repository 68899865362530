export const BedsIcon = ({ className = 'fill-midnight-blue-500' }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-[1.25em] w-auto ${className}`}>
    <path d="M19.863 10.5714V4.85711C19.863 4.66767 19.7877 4.48599 19.6538 4.35203C19.5198 4.21808 19.3382 4.14282 19.1487 4.14282H4.863C4.67356 4.14282 4.49188 4.21808 4.35793 4.35203C4.22397 4.48599 4.14872 4.66767 4.14872 4.85711V10.5714C3.5804 10.5714 3.03535 10.7972 2.63349 11.199C2.23162 11.6009 2.00586 12.1459 2.00586 12.7143V19.8571H3.613V18.4285H20.3987V19.8571H22.0059V12.7143C22.0059 12.1459 21.7801 11.6009 21.3782 11.199C20.9764 10.7972 20.4313 10.5714 19.863 10.5714ZM11.2916 10.5714H5.93443V9.14282C5.93443 8.95338 6.00969 8.7717 6.14364 8.63775C6.2776 8.50379 6.45928 8.42854 6.64872 8.42854H10.5773C10.7667 8.42854 10.9484 8.50379 11.0824 8.63775C11.2163 8.7717 11.2916 8.95338 11.2916 9.14282V10.5714ZM12.7201 9.14282C12.7201 8.95338 12.7954 8.7717 12.9294 8.63775C13.0633 8.50379 13.245 8.42854 13.4344 8.42854H17.363C17.5524 8.42854 17.7341 8.50379 17.8681 8.63775C18.002 8.7717 18.0773 8.95338 18.0773 9.14282V10.5714H12.7201V9.14282Z" />
  </svg>
);
