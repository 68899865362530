'use client';
import React, { useState } from 'react';
import { Button } from '../Atoms/Button';
import { SearchIcon } from '../Atoms/Icons';
import { useRouter } from 'next/navigation';

interface SearchBarMobileProps {
  closeNavMenu: () => void;
}

export function SearchBarMobile({ closeNavMenu }: SearchBarMobileProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const router = useRouter();

  const submitSearch = () => {
    closeNavMenu();
    setSearchValue('');
    router.push(`/search/?q=${searchValue}`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
  };

  return (
    <li className="">
      <div
        className={`flex items-center gap-4 overflow-hidden border-b border-b-midnight-blue-500 pt-4 transition-all`}>
        <input
          type="text"
          className={`h-full w-full border-none bg-transparent px-0 text-lg font-medium text-midnight-blue-500 placeholder-midnight-blue-500 placeholder-opacity-50`}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button color={'midnightBlue'} element="button" size="circleSmall" onClick={submitSearch}>
          <SearchIcon className="" />
        </Button>
      </div>
    </li>
  );
}
