'use client';

import { CategoryFragment } from '@/__generated__/graphql';
import { Button } from '@/components/Atoms/Button';
import { DownChevron } from '@/components/Atoms/Icons';
import ImageAsset, { MaybeImage } from '@/components/Atoms/ImageAsset';
import { FluidContainer } from '@/components/Atoms/Layouts';
import ExpandingBlock from '@/components/utility/ExpandingBlock';
import { useState } from 'react';

interface PeopleBlockFragment {
  title: string;
  people: {
    id: string;
    name: string;
    role: CategoryFragment[];
    bio: string;
    image?: MaybeImage;
  }[];
}

/**
 * Renders a single person block.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.person - The person data.
 * @returns {JSX.Element} The rendered component.
 */
function PeopleBlockInner({ person }: { person: PeopleBlockFragment['people'][0] }) {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="w-full py-12">
      <div className="flex flex-col gap-8 md:flex-row md:gap-12">
        <ImageAsset
          data={person.image}
          width={234}
          height={234}
          className="h-60 w-60 rounded-xl object-cover"
        />
        <div>
          <h4 className="mb-2">{person.name}</h4>
          {person.role.length > 0 && <p className="mb-6">{person.role[0].title}</p>}
          <ExpandingBlock expanded={expanded} startHeight={52}>
            <div className="text-black" dangerouslySetInnerHTML={{ __html: person.bio }} />
          </ExpandingBlock>
          <div className="pt-6">
            <Button
              size="small"
              color={expanded ? 'midnightBlue' : 'lightBlue'}
              onClick={() => setExpanded(!expanded)}
              element="button">
              {expanded ? <>Less &ndash;</> : 'More +'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Renders a block component for displaying a list of people.
 *
 * @param data - The data for the people block.
 * @returns The rendered PeopleBlock component.
 * @example
  <PeopleBlock
    title="Company secretary"
    people={[
      {
        id: '1',
        name: 'Jane Doe',
        role: 'CEO',
        bio: `Nisi anim et labore do ullamco dolor dolor reprehenderit aute proident`,
        image: { url: 'https://placehold.co/240x240/png/', alt: 'Jane Doe' },
      },
      ...
    ]}
  />
 */
export const PeopleBlock = (data: PeopleBlockFragment) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <FluidContainer width="article" bottomPadding={false}>
      <div className="">
        <button
          className="flex w-full items-center justify-between py-12"
          onClick={() => setExpanded(!expanded)}>
          <h2 className="text-left">{data.title}</h2>
          <DownChevron className="shrink-0 stroke-midnight-blue-500 text-xl" />
        </button>
      </div>
      <div className="flex border-b border-b-grey-100">
        <div>
          <div
            className={`${expanded ? 'max-h-full' : 'max-h-0'} divide-y divide-grey-100 overflow-hidden transition-all duration-500 ease-in-out`}>
            {data.people.map((person) => (
              <PeopleBlockInner key={person.id} person={person}></PeopleBlockInner>
            ))}
          </div>
        </div>
      </div>
    </FluidContainer>
  );
};
