'use client';
import { NavTreeItem } from '@/lib/getters/getNav';
import Link from 'next/link';
import React from 'react';
import { CloseIcon } from '../Atoms/Icons';
import { LinkArrow } from '../Atoms/LinkArrow/LinkArrow';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';

export function HeaderNavSubPanel({
  parentItem,
  show,
  toggleSubMenu,
}: {
  parentItem: NavTreeItem;
  show: boolean;
  toggleSubMenu: (index: number | null) => void;
}) {
  const description = parentItem?.customAttributes?.find(
    (node) => node?.attribute == 'description',
  )?.value;

  return (
    <div
      className={`absolute left-0 top-full z-50 h-auto w-full bg-turquoise-400 text-midnight-blue-500 ${
        show ? 'block' : 'hidden'
      }`}>
      <div className="relative mx-auto flex max-w-screen-2xl gap-12 px-8 py-12">
        <button className="absolute right-8 top-12" onClick={() => toggleSubMenu(null)}>
          <CloseIcon className="stroke-midnight-blue-500" />
        </button>
        <div className="flex w-1/3 flex-col gap-6">
          <h3>{parentItem.title}</h3>
          <p className="max-w-80 text-base font-normal">{description || ''}</p>
          {parentItem.nodeUri && (
            <LinkArrow
              onClick={() => toggleSubMenu(null)}
              linkHref={normalizeLinkHref(parentItem.nodeUri)}>
              Learn More
            </LinkArrow>
          )}
        </div>
        <ul className="mr-8 grid h-min w-2/3 grid-flow-col-dense grid-cols-3 grid-rows-4 content-start gap-3">
          {parentItem.children.map((child) => (
            <li key={child.id} className="">
              <Link
                onClick={() => toggleSubMenu(null)}
                href={normalizeLinkHref(child.nodeUri) || '#'}
                className="text-m">
                {child.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
