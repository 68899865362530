import type { Config } from 'tailwindcss';
import type { PluginUtils } from 'tailwindcss/types/config';
import forms from '@tailwindcss/forms';

export const genuGlobalColours = {
  // 'blue': {
  //   400: '#001f6f',
  //   500: '#001f6f',
  // },
  // 'green': {
  //   600: '#001f6f',
  //   500: '#001f6f',
  // },
  // 'sky': {
  //   300: '#26cad3',
  //   200: '#DCF6FE',
  //   100: '#F1F8FA',
  // },
  'blue': {
    400: '#f00',
    500: '#f00',
  },
  'green': {
    600: '#f00',
    500: '#f00',
  },
  'sky': {
    300: '#f00',
    200: '#f00',
    100: '#f00',
  },
  'grey': {
    100: '#E6EAF2',
  },
  'midnight-blue': {
    200: '#F2F4FC',
    300: '#F8F9FD',
    500: '#001F6F',
  },
  'turquoise': { 400: '#26CAD3' },
  'white': '#FFFFFF',
  'black': '#000000',
  'inherit': 'inherit',
  'transparent': 'transparent',
};

const config: Config = {
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    colors: genuGlobalColours,
    fontFamily: {
      sans: ['"roc-grotesk"', 'system-ui', 'sans-serif'],
    },
    screens: {
      'xs': '375px',
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1152px',
      '2xl': '1440px',
    },
    extend: {
      fontSize: {
        'xs': ['0.75rem', '1.25rem'],
        'sm': ['0.875rem', { lineHeight: '1.5rem', fontWeight: 500 }],
        'base': ['1.125rem', '1.75rem'],
        'm': ['1.25rem', '2rem'],
        'lg': ['1.5rem', '2.25rem'],
        'xl': ['2rem', '2.75rem'],
        '2xl': ['1.5rem', { lineHeight: '2.25rem', fontWeight: 700 }],
        '3xl': ['1.75rem', { lineHeight: '2.5rem', fontWeight: 700 }],
        's3xl': ['1.625rem', { lineHeight: '2.375rem', fontWeight: 700 }],
        '4xl': ['2.25rem', { lineHeight: '2.625rem', fontWeight: 700 }],
        's4xl': ['2rem', { lineHeight: '2.5rem', fontWeight: 700 }],
        '5xl': ['3rem', { lineHeight: '3.25rem', fontWeight: 700 }],
        's5xl': ['2.25rem', { lineHeight: '2.625rem', fontWeight: 700 }],
        '6xl': ['3.5rem', { lineHeight: '4.875rem', fontWeight: 700 }],
        's6xl': ['2.5rem', { lineHeight: '3rem', fontWeight: 700 }],
        '7xl': ['4.625rem', { lineHeight: '5.125rem', fontWeight: 700 }],
        's7xl': ['2.75rem', { lineHeight: '3.375rem', fontWeight: 700 }],
      },
      borderRadius: {
        '4xl': '2.5rem',
        '5xl': '3.5rem',
      },
      spacing: {
        18: '4.5rem',
      },
      typography: ({ theme }: PluginUtils) => ({
        DEFAULT: {
          css: {
            '--tw-prose-body': theme('colors.black'),
            '--tw-prose-headings': theme('colors.midnight-blue[500]'),
            '--tw-prose-lead': theme('colors.black'),
            '--tw-prose-links': theme('colors.midnight-blue[500]'),
            '--tw-prose-bold': theme('colors.black'),
            '--tw-prose-counters': theme('colors.black'),
            '--tw-prose-bullets': theme('colors.black'),
            '--tw-prose-hr': theme('colors.black'),
            '--tw-prose-quotes': theme('colors.black'),
            '--tw-prose-quote-borders': theme('colors.black'),
            '--tw-prose-captions': theme('colors.black'),
            '--tw-prose-code': theme('colors.black'),
            '--tw-prose-pre-code': theme('colors.black'),
            '--tw-prose-pre-bg': theme('colors.black'),
            '--tw-prose-th-borders': theme('colors.black'),
            '--tw-prose-td-borders': theme('colors.black'),
            '--tw-prose-invert-body': theme('colors.black'),
            '--tw-prose-invert-headings': theme('colors.black'),
            '--tw-prose-invert-lead': theme('colors.black'),
            '--tw-prose-invert-links': theme('colors.black'),
            '--tw-prose-invert-bold': theme('colors.black'),
            '--tw-prose-invert-counters': theme('colors.black'),
            '--tw-prose-invert-bullets': theme('colors.black'),
            '--tw-prose-invert-hr': theme('colors.black'),
            '--tw-prose-invert-quotes': theme('colors.black'),
            '--tw-prose-invert-quote-borders': theme('colors.black'),
            '--tw-prose-invert-captions': theme('colors.black'),
            '--tw-prose-invert-code': theme('colors.black'),
            '--tw-prose-invert-pre-code': theme('colors.black'),
            '--tw-prose-invert-pre-bg': theme('colors.black'),
            '--tw-prose-invert-th-borders': theme('colors.black'),
            '--tw-prose-invert-td-borders': theme('colors.black'),
          },
        },
      }),
    },
  },
  corePlugins: {
    aspectRatio: false,
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    forms({ strategy: 'class' }),
  ],
};
export default config;
