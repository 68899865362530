import { VariantProps, cva } from 'class-variance-authority';

type CardIconProps = VariantProps<typeof CardIconStyles> & {
  icon: React.ReactNode;
};

const CardIconStyles = cva('flex items-center justify-center w-12 h-12 mx-6 md:mx-8 mt-6 md:mt-8', {
  variants: {
    color: {
      green: 'fill-green-600',
      midnightBlue: 'fill-midnight-blue-500',
    },
  },
  defaultVariants: {
    color: 'midnightBlue',
  },
});

const CardIcon = ({ icon, color }: CardIconProps) => {
  return <div className={CardIconStyles({ color })}>{icon}</div>;
};

export default CardIcon;
