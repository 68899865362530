import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { RightArrow } from '../Icons';
import { MouseEventHandler } from 'react';

export type ButtonVariantProps = VariantProps<typeof ButtonStyles> &
  React.ComponentPropsWithRef<'a'> & {
    arrow?: boolean;
    element?: React.ElementType;
    href?: string | null | undefined;
    onClick?: MouseEventHandler<HTMLAnchorElement> | MouseEventHandler<HTMLButtonElement>;
  };

// Define base styles and variants
const ButtonStyles = cva(
  'rounded-full inline-flex items-center font-medium group leading-none transition-colors flex-shrink-0', // Base styles
  {
    variants: {
      color: {
        darkBlue: 'bg-blue-500 text-white hover:border-white border border-blue-500 stroke-white',
        blue: 'bg-sky-300 text-green-600 stroke-green-600',
        // lightBlue:
        //   'bg-sky-200 text-green-600 hover:bg-sky-300 stroke-green-600 group-hover:bg-sky-300',
        skyBlue:
          'bg-sky-100 text-green-600 hover:bg-green-600 stroke-green-600 group-hover:bg-green-600 hover:text-white group-hover:text-white',
        darkGreen: 'bg-green-600 text-sky-200 hover:bg-green-700 stroke-sky-200',
        // white: 'bg-white text-green-600 hover:bg-sky-200 stroke-green-600',
        // whiteOutlined:
        //   'bg-white text-green-600 hover:bg-sky-200 stroke-green-600 border border-green-600',
        greenOutline: '  stroke-green-600 border border-green-600 text-green-600',
        lightBlueOutline: 'text-sky-200  stroke-sky-200 border border-sky-200',
        // new colours
        midnightBlue: 'bg-midnight-blue-500 text-white hover:bg-midnight-blue-600 stroke-white',
        midnightBlueOutline:
          'text-midnight-blue-500  stroke-midnight-blue-500 border border-midnight-blue-500',
        turquoise: 'bg-turquoise-400 text-midnight-blue-500 hover:bg-turquoise-500',
        lightBlue:
          'bg-midnight-blue-200 text-midnight-blue-500 hover:bg-midnight-blue-300 stroke-midnight-blue-500 group-hover:bg-midnight-blue-300',
        white: 'bg-white text-midnight-blue-500 hover:bg-white stroke-midnight-blue-500 ',
        whiteOutline: 'text-white  stroke-white border border-white',
        whiteOutlined:
          'bg-white text-midnight-blue-500 hover:bg-midnight-blue-200 stroke-midnight-blue-500 border border-midnight-blue-500',
        turquoiseOutline: 'text-turquoise-400  stroke-turquoise-400 border border-turquoise-400',
      },
      size: {
        xsmall: 'h-6 px-2 text-xs',
        small: 'h-8 px-3 text-sm',
        medium: 'h-12 px-5 text-base',
        large: 'h-14 px-8 text-lg',
        circle: 'h-12 w-12 px-0 justify-center',
        circleSmall: 'h-8 w-8 px-0 justify-center text-sm',
        circleResponsive: 'h-8 w-8 sm:h-12 sm:w-12 text-sm lg:text-lg px-0 justify-center',
      },
    },
    defaultVariants: {
      color: 'turquoise',
      size: 'medium',
    },
  },
);

const Button = ({
  children,
  color,
  size,
  href,
  arrow = false,
  element = Link,
  onClick,
  ...props
}: ButtonVariantProps) => {
  const El = element;
  return (
    <El href={href || '#'} {...props} onClick={onClick} className={ButtonStyles({ color, size })}>
      {children}
      {arrow && (
        <RightArrow className="ml-2 translate-x-0 transition-transform group-hover:translate-x-[2px]" />
      )}
    </El>
  );
};

export default Button;
