'use client';

import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { ThemeProvider } from '@emotion/react';
import { Formie } from '@liquorice/formie-react';
import { firstNonNullable } from '@liquorice/utils';
import { genuTheme } from './MuiTheme';

const FORM_BLOCK_FRAGMENT = gql(`
  fragment FormBlock on blocks_blockForm_BlockType {
    heading
    htmlContentSimple
    form {
      id
    }
  }
`);

export interface FormBlockProps extends BlockMeta {}

const FormBlock = createBlock(FORM_BLOCK_FRAGMENT, (data, props: FormBlockProps) => {
  // TODO: Implement liquorice form component
  const formId = firstNonNullable(data.form)?.id;
  return (
    <FluidContainer width="article">
      <a id={props.anchor} />
      <h1>{data.heading}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.htmlContentSimple || '' }} />
      {/* FORM GOES HERE */}
      <ThemeProvider theme={genuTheme}>
        {formId && <Formie queryVariables={{ id: formId }} hideRecaptchaBadge />}
      </ThemeProvider>
    </FluidContainer>
  );
});

export default FormBlock;
