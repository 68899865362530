import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

type RoundedSectionProps = VariantProps<typeof RoundedSectionStyles> & {
  children: ReactNode;
};

const RoundedSectionStyles = cva(
  'rounded-t-4xl lg:rounded-t-5xl', // Base styles
  {
    variants: {
      color: {
        darkBlue: 'bg-blue-500 text-white',
        darkGreen: 'bg-green-600 text-sky-300',
        // new colours
        white: 'bg-white text-midnight-blue-500',
        midnightBlue: 'bg-midnight-blue-500 text-white',
        turquoise: 'bg-turquoise-400 text-midnight-blue-500',
      },
      padding: {
        small: 'py-8',
        medium: 'py-12',
        large: 'py-12 lg:py-18',
      },
      noOverlap: {
        false: '-mt-16',
        true: '-mt-0 mb-16',
      },
    },
    defaultVariants: {
      color: 'white', // Default size if none is provided
      padding: 'large',
      noOverlap: false,
    },
  },
);

export const RoundedSection = ({
  color,
  children,
  padding,
  noOverlap = false,
  ...rest
}: RoundedSectionProps) => {
  return (
    <div className={RoundedSectionStyles({ color, padding, noOverlap })} {...rest}>
      {children}
    </div>
  );
};
